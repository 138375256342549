import Cookies from "js-cookie";
import React from "react";
import { useGetAllCategoryQuery } from "../../features/category/categoryApiSlice";
import CategoryCard from "../Card/CategoryCard";
import CommonComponent from "../CommonComponent/CommonComponent";
import Error from "../Error/Error";
import NoDataFound from "../NodataFound/NoDataFound";
import CategoryStaticBanner from "./CategoryStaticBanner";

const Categories = () => {
  const access_token = Cookies.get("ayykori");
  const { data, isLoading, isSuccess, isError } =
    useGetAllCategoryQuery({ access_token }, { refetchOnReconnect: true }) ||
    {};
  let content = null;
  // handle loading case
  if (isLoading && !isSuccess) {
    content = (
      <>
        <div
          className=" px-3 md:px-[55px] grid grid-cols-1 sm:grid-cols-2  md:grid-cols-3 lg:grid-cols-5  
      gap-y-6 gap-x-8"
        >
          {Array(10)
            .fill()
            ?.map((item, i) => (
              <div
                key={i}
                className="t w-full h-20 rounded shadow animate-pulse bg-gray-200"
              ></div>
            ))}
        </div>
      </>
    );
  }
  // handle success case
  if (!isLoading && isSuccess && !isError) {
    const myArray = [
      "online tickets",
      "mother & baby",
      "medicine",
      "life style",
      "legal service platform",
      "home appliances",
      "health and beauty",
      "food and groceries",
      "education",
      "electronics & gadgets",
    ];

    const filteredCategories = data?.category?.filter((category) =>
      myArray.includes(category._id)
    );

    content = (
      <>
        <div
          className="w-full px-3 md:px-[55px] grid grid-cols-2 sm:grid-cols-2  md:grid-cols-3 lg:grid-cols-5
      gap-y-3 md:gap-y-6 gap-x-3 md:gap-x-8"
        >
          {filteredCategories &&
            isSuccess &&
            filteredCategories?.map((item, i) => (
              <CategoryCard data={item?.uniqueCategories} key={i} />
            ))}
        </div>
      </>
    );
  }
  // handle error case
  if (!isLoading && isError) {
    content = (
      <>
        <Error />
      </>
    );
  }
  // handle empty case
  if (!isLoading && isSuccess && data?.resData?.length === 0) {
    content = (
      <>
        <NoDataFound />
      </>
    );
  }
  return (
    <div className="my-10 md:my-16">
      <CommonComponent
        data={{
          title: "Categories",
          link: "/brands/categories",
          linktitle: "See All",
          show: true,
        }}
      />

      {/* {content} */}

      <div>
        <CategoryStaticBanner />
      </div>
    </div>
  );
};

export default Categories;
