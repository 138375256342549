import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-hot-toast";
import { AiOutlineLink, AiOutlineShareAlt } from "react-icons/ai";
import { BsActivity } from "react-icons/bs";
import { FiChevronDown } from "react-icons/fi";
import { IoCalculatorOutline } from "react-icons/io5";
import { MdEdit } from "react-icons/md";
import { SiGoogleclassroom } from "react-icons/si";
import { TbPointFilled } from "react-icons/tb";
import { TfiSettings, TfiWallet } from "react-icons/tfi";
import { VscSignOut } from "react-icons/vsc";
import { useDispatch } from "react-redux";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import avatar from "../../Assets/images/placeholder-image/avatar.png";
import BackButton from "../../components/CustomeButton/BackButton";
import UserDashboardMobile from "../../components/MobileDivicesComponent/UserDashboardMobile";
import { isLogoutUser } from "../../features/auth/authSlice";
import {
  useGetSingleUserQuery,
  useUpdateUserMutation,
} from "../../features/user/userApiSlice";
import { auth } from "../../firebase/Firebase";
import CommonLayout from "../../layout/CommonLayout";
const UserDasboard = () => {
  const { pathname } = useLocation();

  const [showWallet, setShowWallet] = useState(false);
  // const [showLeaderBoard, setShowLeaderBoard] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [showBrands, setShowBrands] = useState(false);
  const [divStyle, setDivStyle] = useState(false);
  const [showCalculation, setCalculation] = useState(false);
  // const [showProducts, setShowProducts] = useState(false);
  const [
    updateUser,
    {
      isLoading: isLoadingUser,
      isError: isErrorUser,
      isSuccess: isSuccessUser,
      error: errorUser,
    },
  ] = useUpdateUserMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [imagesize, setImageSize] = useState(0);
  const [photo, setPhoto] = useState("");
  const access_token = Cookies?.get("ayykori");
  const { isLoading, data } =
    useGetSingleUserQuery(access_token, { refetchOnReconnect: true }) || {};

  // converting normal image to base64 string
  const convertImageToBase64 = (file) => {
    if (file?.type?.split("/")[0] !== "image") {
      toast.error("Only Image");
    } else {
      const reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
        reader.onload = () => {
          setPhoto(reader.result);
        };
        reader.onerror = (error) => {
          // console.log(error);
        };
      }
    }
  };

  // check file size
  const fileSize = (_size) => {
    // console.log(_size/(824*1024))
    var fSExt = ["Bytes", "KB", "MB", "GB"],
      i = 0;
    while (_size > 900) {
      _size /= 1024;
      i++;
    }
    var exactSize = Math.round(_size * 100) / 100 + " " + fSExt[i];
    return exactSize;
  };

  useEffect(() => {
    if (photo) {
      if (
        (imagesize.toString().split(" ")[1] === "MB" ||
          imagesize.toString().split(" ")[1] === "GB") &&
        Number(imagesize.toString().split(" ")[0] > 1)
      ) {
        toast.error("Image is too large");
      } else {
        updateUser({ access_token, data: { photo: photo } });
      }
    }
  }, [photo, access_token, imagesize, updateUser]);

  const handleLogout = () => {
    Cookies.remove("ayykori");
    auth.signOut();
    dispatch(isLogoutUser());
    navigate("/");
    toast.success("Logout Successfull!!");
  };
  // console.log(pathname?.split("/")[2]);
  let title = "";
  if (pathname?.split("/")[2]) {
    let arr = pathname?.split("/")[2]?.split("-");
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].slice(0, 1).toUpperCase() + arr[i].slice(1);
    }
    title = arr.join(" ");
  }

  // for active link style
  const activeNavLink = ({ isActive }) => {
    return {
      color: isActive ? "#11998E" : "#6b7280",
      borderLeft: isActive ? "2px solid rgb(17 153 142)" : "none",

      // borderColor: isActive ? "#7efff5" : "#ffffff",
    };
  };
  // for dropdown NavLink style
  const dropdownActiveNavLink = ({ isActive }) => {
    return {
      color: isActive ? "#11998E" : "#6b7280",
      // borderLeft: isActive ? "2px solid rgb(17 153 142)" : "none",
      // borderColor: isActive ? "#7efff5" : "#ffffff",
    };
  };

  // update profile success message
  useEffect(() => {
    if (isSuccessUser && !isErrorUser) {
      toast.success("Profile Updated Successfully!!");
      navigate("/my-account");
    }
  }, [isSuccessUser, isErrorUser]);

  // update profile error message
  useEffect(() => {
    if (isErrorUser) {
      toast.error("Profile Image Not Updated!!");
    }
  }, [isErrorUser]);

  return (
    <>
      <Helmet>
        <title>
          Dashboard{title ? `: ${title} | ` : " | "}
          Ayykori
        </title>
      </Helmet>
      <CommonLayout>
        <div
          className={` w-full px-3 py-4 md:px-[55px] h-[640px] ${
            pathname === "/my-account" ? "bg-white" : "bg-gray-100"
          } md:bg-gray-100 flex justify-center items-center `}
        >
          <div className="w-full h-full rounded-md flex flex-col md:flex-row gap-x-2">
            {/* mobile design start */}

            <UserDashboardMobile
              auth={auth}
              data={data}
              isLoadingUser={isLoadingUser}
              isLoading={isLoading}
              pathname={pathname}
            />
            {/* mobile design end */}
            <div
              className="hidden w-full md:w-[230px] h-full bg-gradient-to-r from-white  to-transparent rounded-md md:flex flex-col items-center py-4 overflow-y-auto scroll-smooth 
    scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-white 
    scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-1"
            >
              <div className="w-24 h-24 relative">
                <img
                  src={
                    auth?.currentUser?.photoURL ||
                    data?.user?.googlePhoto ||
                    data?.user?.photo?.url ||
                    avatar
                  }
                  alt=""
                  className="w-full h-full rounded-full border object-contain"
                />
                {pathname === "/my-account/update-account" &&
                  !isLoadingUser && (
                    <label
                      htmlFor="image"
                      title="Update Profile Image"
                      className="absolute cursor-pointer bottom-1 right-1 text-white p-1 rounded-full bg-primaryColor"
                    >
                      <MdEdit size={15} />
                      <input
                        id="image"
                        type="file"
                        className="hidden"
                        onChange={(e) => {
                          setImageSize(fileSize(e.target?.files[0]?.size));
                          convertImageToBase64(e.target.files[0]);
                        }}
                      />
                    </label>
                  )}

                {(isLoading || isLoadingUser) && (
                  <span className="absolute top-[50%] left-[50%] flex justify-center items-center">
                    <FadeLoader
                      color="#ffffff"
                      height={5}
                      margin={-9}
                      radius={5}
                      width={3}
                    />
                  </span>
                )}
                {/* <span
                  className={`absolute bottom-0 right-0 w-3 h-3 ${
                    data?.user?.userStatus === "Active"
                      ? "bg-green-500"
                      : data?.user?.userStatus === "Restricted"
                      ? "bg-blue-500"
                      : "bg-red-500"
                  } rounded-full`}
                ></span> */}
              </div>
              <h1
                className={`${
                  data?.user?.fullname.length > 16
                    ? "text-sm"
                    : data?.user?.fullname.length > 12
                    ? "text-base"
                    : "text-lg"
                } font-outfit text-gray-600 font-medium bg-blue-50 px-2 py-0.5 rounded-md mt-2`}
              >
                {data?.user?.fullname}
              </h1>
              <p className="text-xs font-outfit text-primaryColor">
                {data?.user?.email}
              </p>

              <div className="hidden w-full mt-4 md:flex flex-col gap-y-2">
                <div
                  className={` transition-all duration-500 ease-in cursor-pointer text-gray-500 flex flex-col`}
                >
                  <NavLink
                    style={activeNavLink}
                    to="/my-account"
                    className="px-4 py-2  transition-all duration-500 ease-in-out cursor-pointer  hover:text-primaryColor  flex items-center gap-x-2"
                    end
                  >
                    <TfiWallet />
                    Cashback Balance
                  </NavLink>
                </div>

                <div
                  className={` transition-all duration-500 ease-in cursor-pointer text-gray-500 flex flex-col`}
                >
                  <NavLink
                    style={activeNavLink}
                    to="/my-account/cashback-calculation"
                    className="px-4 py-2 transition-all duration-500 ease-in-out cursor-pointer  hover:text-primaryColor  flex items-center gap-x-2"
                    end
                  >
                    <IoCalculatorOutline />
                    Cashback Calculator
                  </NavLink>
                </div>

                <div
                  className={` transition-all duration-500 ease-in cursor-pointer text-gray-500 flex flex-col`}
                >
                  <NavLink
                    style={activeNavLink}
                    to="/my-account/my-referrals"
                    className="px-4 py-2 transition-all duration-500 ease-in-out cursor-pointer  hover:text-primaryColor  flex items-center gap-x-2"
                    end
                  >
                    <AiOutlineShareAlt />
                    Referrals
                  </NavLink>
                </div>

                <div
                  className={` transition-all duration-500 ease-in cursor-pointer flex flex-col  `}
                >
                  <div
                    className={`px-4 py-2   flex items-center justify-between ${
                      showCalculation
                        ? "text-[#11998E] border-l-[2px] border-[rgb(17_153_142)] border-solid "
                        : "text-gray-500"
                    } `}
                  >
                    <div
                      onClick={(e) => {
                        setCalculation(!showCalculation);
                        setShowSettings(false);
                      }}
                      className={`flex items-center gap-x-2 `}
                    >
                      <IoCalculatorOutline />
                      Affiliate
                    </div>
                    <FiChevronDown
                      onClick={(e) => {
                        setCalculation(!showCalculation);
                        setShowSettings(false);
                      }}
                      className={`${
                        showCalculation && "rotate-180"
                      } transition-all duration-500`}
                    />
                  </div>

                  {showCalculation && (
                    <div
                      className={` px-4   rounded-md w-full flex flex-col ${
                        setCalculation ? "opacity-100" : "opacity-0"
                      } transition-all duration-500 ease-in`}
                    >
                      <NavLink
                        style={dropdownActiveNavLink}
                        to="/my-account/earning-balance"
                        className="px-4 py-2 transition-all duration-500 ease-in-out cursor-pointer text-sm hover:text-primaryColor  flex items-center gap-x-2"
                        end
                      >
                        <TfiWallet />
                        Affiliate Balance
                      </NavLink>
                      <NavLink
                        style={dropdownActiveNavLink}
                        to="/my-account/earning-calculation"
                        className="px-4 py-2 transition-all duration-500 ease-in-out cursor-pointer flex hover:text-primaryColor text-sm items-center gap-x-2"
                        end
                      >
                        <IoCalculatorOutline />
                        Affiliate Calculator
                      </NavLink>

                      <NavLink
                        style={dropdownActiveNavLink}
                        to="/my-account/make-affiliate-link"
                        className="px-4 py-2 transition-all duration-500 ease-in-out cursor-pointer text-sm hover:text-primaryColor  flex items-center gap-x-2"
                        end
                      >
                        <AiOutlineLink />
                        Make Affiliate Link
                      </NavLink>

                      <NavLink
                        style={dropdownActiveNavLink}
                        to="/my-account/user-activity-log"
                        className="px-4 py-2 transition-all duration-500 ease-in-out cursor-pointer text-sm hover:text-primaryColor  flex items-center gap-x-2"
                        end
                      >
                        <BsActivity />
                        Activity Log
                      </NavLink>

                      <NavLink
                        style={dropdownActiveNavLink}
                        to="/my-account/online-workshop"
                        className="px-4 py-2 transition-all duration-500 ease-in-out cursor-pointer text-sm hover:text-primaryColor  flex items-center gap-x-2"
                        end
                      >
                        <SiGoogleclassroom
                          className={` ${
                            dropdownActiveNavLink ? "" : "text-gray-500"
                          }`}
                        />
                        Online Workshop
                      </NavLink>
                    </div>
                  )}
                </div>

                {/* <div
                className={`px-4 py-2 transition-all duration-500 ease-in cursor-pointer text-gray-500 flex flex-col`}
              >
                <div className="flex items-center justify-between">
                  <div
                    onClick={() => setShowLeaderBoard(!showLeaderBoard)}
                    className="flex items-center gap-x-2"
                  >
                    <MdOutlineLeaderboard />
                    Leaderboard
                  </div>
                  <FiChevronDown
                    onClick={() => setShowLeaderBoard(!showLeaderBoard)}
                    className={`${
                      showLeaderBoard && "rotate-180"
                    } transition-all duration-500`}
                  />
                </div>
                {showLeaderBoard && (
                  <div
                    className={`rounded-md w-full flex flex-col ${
                      showLeaderBoard ? "opacity-100" : "opacity-0"
                    } transition-all duration-500 ease-in`}
                  >
                    <Link
                      to="/my-account/cashback-leaderboard"
                      className="px-4 py-2 transition-all duration-500 ease-in-out cursor-pointer text-gray-500 hover:text-primaryColor flex text-sm items-center gap-x-2"
                    >
                      <TbPointFilled />
                      Cashback Leaderboard
                    </Link>
                    <Link
                      to="/my-account/earning-leaderboard"
                      className="px-4 py-2 transition-all duration-500 ease-in-out cursor-pointer text-gray-500 flex hover:text-primaryColor text-sm items-center gap-x-2"
                    >
                      <TbPointFilled />
                      Earning Leaderboard
                    </Link>
                    <Link
                      to="/my-account/referral-leaderboard"
                      className="px-4 py-2 transition-all text-sm duration-500 ease-in-out cursor-pointer text-gray-500 hover:text-primaryColor flex items-center gap-x-2"
                    >
                      <TbPointFilled />
                      Referral Leaderboard
                    </Link>
                  </div>
                )}
              </div> */}
                {/* <CustomLink
                to={"/my-account/personal-info"}
                className="px-4 py-2 transition-all duration-500 ease-in-out cursor-pointer text-gray-500 flex items-center gap-x-2"
              >
                <RiShieldUserLine />
                Personal Information
              </CustomLink> */}

                <div
                  className={` transition-all duration-500 ease-in cursor-pointer text-gray-500 flex flex-col`}
                >
                  <div
                    className={` px-4 py-2  flex items-center justify-between ${
                      showSettings
                        ? "text-[#11998E] border-l-[2px] border-[rgb(17_153_142)] border-solid "
                        : "text-gray-500"
                    } `}
                  >
                    <div
                      onClick={(e) => {
                        setShowSettings(!showSettings);
                        setShowWallet(false);
                      }}
                      className="flex items-center gap-x-2"
                    >
                      <TfiSettings />
                      Account Setting
                    </div>
                    <FiChevronDown
                      onClick={(e) => {
                        setShowSettings(!showSettings);
                        setShowWallet(false);
                      }}
                      className={`${
                        showSettings && "rotate-180"
                      } transition-all duration-500`}
                    />
                  </div>
                  {showSettings && (
                    <div
                      className={`rounded-md w-full flex flex-col ${
                        setCalculation ? "opacity-100" : "opacity-0"
                      } transition-all duration-500 ease-in`}
                    >
                      <NavLink
                        style={dropdownActiveNavLink}
                        to="/my-account/update-account"
                        className="px-4 py-2 transition-all duration-500 ease-in-out cursor-pointer hover:text-primaryColor flex text-sm items-center gap-x-2"
                        end
                      >
                        <TbPointFilled />
                        Edit Profile
                      </NavLink>
                      <NavLink
                        style={dropdownActiveNavLink}
                        to="/my-account/update-password"
                        className="px-4 py-2 transition-all duration-500 ease-in-out cursor-pointer flex hover:text-primaryColor text-sm items-center gap-x-2"
                        end
                      >
                        <TbPointFilled />
                        Change Password
                      </NavLink>
                    </div>
                  )}
                </div>

                <div
                  onClick={handleLogout}
                  className="px-4 py-2 transition-all duration-500 ease-in-out cursor-pointer text-gray-500 flex items-center gap-x-2"
                >
                  <VscSignOut />
                  Sign Out
                </div>
              </div>
            </div>

            <div
              className={`w-full md:w-[80%] h-full ${
                pathname !== "/my-account"
                  ? "flex flex-col"
                  : "hidden md:flex flex-row"
              }`}
            >
              {/* back button  start */}

              <BackButton pathname={pathname}> </BackButton>

              {/* back button  end */}

              <div
                className={`w-full h-full bg-white rounded-md p-4 overflow-y-auto scroll-smooth 
    scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-white 
    scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-0 flex-col gap-y-2 `}
              >
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </CommonLayout>
    </>
  );
};

export default UserDasboard;
