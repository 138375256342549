import React from "react";

const BrandPageSteper = ({ type, response }) => {
  const { data } = response;
  const brandDetails = data?.resData[0];
  return (
    <div className="w-full flex flex-col gap-y-7 md:gap-y-10">
      <div className=" flex items-center justify-between w-full relative h-[2px] text-xs md:text-base">
        <div className="w-28 text-center -ml-4 text-gray-700">Order Placed</div>
        <div className="w-28 text-center text-gray-700">Notified In</div>
        <div className="w-28 text-center -mr-1 text-gray-700 whitespace-pre">
          Expected {type === "earning" ? "Affiliate" : "Cashback"}
        </div>
      </div>
      <div className=" flex items-center justify-between w-full relative h-[2px] gap-x-4 md:gap-x-0 bg-gray-300 text-xs md:text-base">
        <button className="px-3 md:px-8 md:py-1 border-[2px] whitespace-pre border-gray-200 text-secondary bg-white rounded-full">
          Today
        </button>
        <button className="px-3 md:px-8 md:py-1 border-[2px] whitespace-pre border-gray-200 text-secondary bg-white rounded-full">
          {brandDetails?.orderNotifyTime}
        </button>
        <button className="px-3 md:px-8 md:py-1 border-[2px] whitespace-pre border-gray-200 text-secondary bg-white rounded-full">
          {brandDetails?.cashbackReturnTime}
        </button>
      </div>
    </div>
  );
};

export default BrandPageSteper;
