import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import ReactPlayer from "react-player";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import youtubeLogo from "../../Assets/images/icon/icons8-youtube-94.png";
import BrandDescription from "../../components/Brands/BrandDescription";
import BrandPageHero from "../../components/Brands/BrandPageHero";
import BrandPageLeftPanel from "../../components/Brands/BrandPageLeftPanel";
import BrandPageRightPanel from "../../components/Brands/BrandPageRightPanel";
import TopBrands from "../../components/Brands/TopBrands";
import Trending from "../../components/Trending/Trending";
import { useGetSingleBrandQuery } from "../../features/brand/brandApiSlice";
import { setBrandDetails } from "../../features/brand/singleBrandSlice";
import { useCreatePageVisitTrackingMutation } from "../../features/campaign/campaignApiSlice";
import CommonLayout from "../../layout/CommonLayout";
const IndividualBrandPage = () => {
  const { id, type } = useParams();
  const access_token = Cookies.get("ayykori");
  const dispatch = useDispatch();
  const response =
    useGetSingleBrandQuery(
      { access_token, id },
      { refetchOnReconnect: true }
    ) || {};
  const { data } = response;

  // console.log(data);
  //! page visit api call start

  const [
    createPageVisitTracking,
    {
      data: campaignData,
      isLoading: campaignIsLoading,
      campaignIsSuccess,
      campaignIsError,
    },
  ] = useCreatePageVisitTrackingMutation();

  //! page visit api call end

  useEffect(() => {
    dispatch(setBrandDetails(data?.resData[0]));
    //? this page visit count start
    if (data?.resData[0]?.brandUnid) {
      createPageVisitTracking({
        page_visit_title: "Brand Page Visit",
        month: new Date(),
        brandUnid: data?.resData[0]?.brandUnid,
      });
    }

    //? this page visit count end
  }, [data?.resData, dispatch, createPageVisitTracking]);

  return (
    <>
      <Helmet>
        <title>{data?.resData[0]?.brandName || "Brand"} | Ayykori</title>
      </Helmet>
      <CommonLayout>
        <BrandPageHero type={type} response={response} />
        {data?.resData[0]?.videoLink && (
          <div className=" md:hidden w-full px-3 md:px-[55px] flex flex-col md:flex-row gap-3 md:gap-6 my-4">
            <div className="w-full h-48 xs:h-60 sm:h-64 md:h-auto max-h-[500px]">
              <ReactPlayer
                width={"100%"}
                height={"100%"}
                playing={true}
                light={true}
                stopOnUnmount={true}
                controls={true}
                playIcon={<img src={youtubeLogo} alt="" className="w-16   " />}
                url={data?.resData[0]?.videoLink}
              />
            </div>
          </div>
        )}

        <div className="w-full px-3 md:px-[55px] flex flex-col md:flex-row gap-3 md:gap-6">
          <BrandPageLeftPanel response={response} />
          <BrandPageRightPanel type={type} response={response} />
        </div>
        {/* <BrandPageFeaturesCards /> */}
        {/* <YouMayLikeBrands
          type={type}
          category={data?.resData[0]?.categories[0]?.categoryName}
        /> */}
        {data?.resData[0]?.brandDomain === "othoba.com" ? (
          <Trending
            newData={{ title: "Othoba Exclusive offer", dataLimit: 20 }}
          />
        ) : data?.resData[0]?.brandDomain === "daraz.com.bd" ? (
          <Trending
            newData={{ title: "Daraz Exclusive offer", dataLimit: 20 }}
          />
        ) : (
          ""
        )}

        <TopBrands type={type} />
        <BrandDescription response={response} />
      </CommonLayout>
    </>
  );
};

export default IndividualBrandPage;
