import React from "react";

const CampaignDescription = ({ campaignData }) => {
  return (
    <div className="mt-6">
      <div className="flex items-center py-2 px-2 gap-x-2 text-gray-500 border-b">
        -আয়করি ক্যাশব্যাক হাট Campaign Schedule:
        {campaignData["Campaign Schedule"]}
      </div>
      <div className="flex items-center py-2 px-2 gap-x-2 text-gray-500 border-b">
        - First Order Reward :{campaignData["First Order Reward"]}
      </div>
      <div className="flex items-center py-2 px-2 gap-x-2 text-gray-500 border-b">
        - Cashback Percentage :{campaignData["Cashback Percentage"]}
      </div>

      <div className="flex items-center py-2 px-2 gap-x-2 text-gray-500 border-b">
        -Dashboard update:
        {campaignData["Dashboard"] ? `${campaignData["Dashboard"]}` : ""}
      </div>
      {/* <div className="flex items-center py-2 px-2 gap-x-2 text-gray-500 border-b"> */}
      {/* Highest Cashback Receiver will get Surprise Gift */}
      {/* {campaignData["Dashboard"] ? `${campaignData["Dashboard"]}` : ""} */}
      {/* </div> */}
      {/* <div className=" flex items-start py-2 px-2 gap-x-2 text-gray-500">
      <AiOutlineExclamationCircle className="my-1" />
      <ul>
        {brandDetails?.applicableMethods
          ?.split(";")
          ?.map((item, i) =>
            urlRegex.test(item?.trim()) ? "" : <li key={i}>- {item}</li>
          )}
        {urlRegex.test(
          brandDetails?.applicableMethods
            ?.split(";")
            [
              brandDetails?.applicableMethods?.split(";")?.length - 1
            ]?.trim()
        ) && (
          <a
            className=" text-blue-500"
            href={
              brandDetails?.applicableMethods?.split(";")[
                brandDetails?.applicableMethods?.split(";")?.length - 1
              ]
            }
            target="_blank"
            rel="noreferrer"
          >
            - <span className="text-sm hover:underline">Learn More</span>
          </a>
        )}
      </ul>
    </div> */}
    </div>
  );
};

export default CampaignDescription;
