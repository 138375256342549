// import topcategory1 from "../Assets/images/top-3-categories/topcategory1.jpg";
// import topcategory2 from "../Assets/images/top-3-categories/topcategory2.jpg";
// import topcategory3 from "../Assets/images/top-3-categories/topcategory3.jpg";
import topcategory1 from "../Assets/images/top-3-categories/1-01.webp";
import topcategory2 from "../Assets/images/top-3-categories/1-02.webp";
import topcategory3 from "../Assets/images/top-3-categories/1-03.webp";
export const categorystaticData = [
  {
    // title: "Everyday Fresh & Clean with Our Products",
    category: "grocery",
    img: topcategory1,
  },
  {
    // title: "Make your Breakfast Healthy and Easy",
    category: "fashion",
    img: topcategory2,
  },
  {
    // title: "The best Organic Products Online",
    category: "electronics",
    img: topcategory3,
  },
];
