import { useSelector } from "react-redux";
import Form from "../ManualOrder/Form";

const FormModal = ({ showAddUser, setShowAddUser, type, data }) => {
  const { manualOrderResetRef, formResetRef } = useSelector(
    (state) => state?.formReset
  );
  const closeModal = () => {
    setShowAddUser(false);
    if (manualOrderResetRef) manualOrderResetRef();
    if (formResetRef) formResetRef();
  };
  const { brandDetails: singleBrand } = useSelector(
    (state) => state?.singleBrand
  );
  return (
    <div hidden={!showAddUser} className={``}>
      <div className="bg-[rgba(9,30,66,0.4)]  fixed  inset-x-0 px-4 pb-4 inset-0 flex justify-center  items-center z-[10000] backdrop-blur-[1px]  ">
        <div className="bg-white rounded-lg shadow-xl overflow-auto md:overflow-hidden lg:overflow-hidden scroll-smooth transform transition-all  md:w-[700px] md:h-[500px] lg:w-[800px] lg:h-[550px]  w-full max-h-[90%]">
          <div className="p-5 ">
            <div className="header flex justify-between items-center">
              <div className="text-gray-500 ">
                <div className=" flex items-center justify-start gap-1">
                  <img
                    className="w-10  object-cover rounded-md"
                    src={singleBrand?.brandimg}
                    alt={singleBrand?.brandname}
                  />
                  <h2 className="ml-4 text-lg font-semibold text-left">
                    Get Cashback
                  </h2>
                </div>
                <p className="text-sm  text-left">
                  {" "}
                  Fill up the Information & Get Cashback
                </p>
              </div>

              <div className="">
                <button
                  onClick={closeModal}
                  className="bg-gray-50 rounded-full  hover:text-red-500 "
                >
                  <span className="text-2xl p-2" aria-hidden="true">
                    &times;
                  </span>
                </button>
              </div>
            </div>
            <div className="body mt-4 md:mt-8 ">
              <Form />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormModal;
