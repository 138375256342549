import React, { useState } from "react";
import { MdArrowForwardIos } from "react-icons/md";
// Import Swiper React components
import { useMediaQuery } from "react-responsive";
import { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";

import { useGetAllBannerQuery } from "../../features/banner/bannerApiSlice";
import CommonBanner from "../Banner/CommonBanner";

const AfterLoginSlider = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const [swiperRef, setSwiperRef] = useState(null);
  const [bannerRes, setBannerRes] = useState();
  const [shouldFetchData, setShouldFetchData] = useState(true);
  const prevHandler = () => {
    swiperRef.slidePrev();
  };

  const nextHandler = () => {
    swiperRef.slideNext();
  };

  const {
    data: bannerData,
    isLoading: isLoadingBanner,
    isSuccess: isSuccessBanner,
    isError: isErrorBanner,
  } = useGetAllBannerQuery(
    {
      bannerStatus: "Published",
      page: 1,
      limit: 50,
      systemDevice: "pc",
    },
    { skip: isMobile, }
  ) || {};
  const {
    data: bannerMobileData,
    isLoading: isLoadingMobileData,
    isSuccess: isSuccessMobileData,
    isError: isErrorMobileData,
  } = useGetAllBannerQuery(
    {
      bannerStatus: "Published",
      page: 1,
      limit: 50,
      systemDevice: "phone",
    },
    { skip: !isMobile }
  ) || {};

  let content = null;
  let contentMobile = null;

  if (!isLoadingBanner && isSuccessBanner && !isErrorBanner && bannerData) {
    const { data } = bannerData?.result || {};
    content = (
      <>
        {data &&
          data?.map((item, index) => (
            <SwiperSlide key={index}>
              <CommonBanner data={item} tempLink={"/campaign"} />
            </SwiperSlide>
          ))}
      </>
    );
  }
  if (
    !isLoadingMobileData &&
    isSuccessMobileData &&
    !isErrorMobileData &&
    bannerMobileData
  ) {
    const { data } = bannerMobileData?.result || {};
    contentMobile = (
      <>
        {data &&
          data?.map((item, index) => (
            <SwiperSlide key={index}>
              <CommonBanner data={item} />
            </SwiperSlide>
          ))}
      </>
    );
  }

  return (
    <>
      <div>
        {/* this is for desktop */}
        <div className="w-full md:block hidden px-3 md:px-[55px] mt-10 mb-2 md:my-10 relative">
          <button
            className="absolute hidden md:block  left-9 top-[50%] translate-y-[-50%] z-[100] rounded-full p-2 bg-gray-100 shadow-md shadow-gray-300"
            onClick={prevHandler}
          >
            <MdArrowForwardIos
              size={18}
              className="rotate-180 cursor-pointer text-gray-600"
            />
          </button>

          <Swiper
            slidesPerView={1.2}
            spaceBetween={10}
            loop={true}
            autoplay={{ delay: 5000 }}
            modules={[Autoplay]}
            onSwiper={(swiper) => setSwiperRef(swiper)}
            className="mySwiper w-full"
          >
            {content}
          </Swiper>

          <div className="absolute -right-0 sm:-right-8 top-0 bottom-0 w-[20%] h-full bg-gradient-to-r from-transparent via-[rgba(255,255,255,0.8)] to-white z-50"></div>
          <button
            className="hidden md:block absolute right-9 top-[50%] translate-y-[-50%] z-[100] rounded-full p-2 bg-gray-100 shadow-md shadow-gray-300"
            onClick={nextHandler}
          >
            <MdArrowForwardIos
              size={18}
              className="cursor-pointer text-gray-600"
            />
          </button>
        </div>

        {/* this is for mobile version */}
        <div className="w-full block md:hidden px-3 md:px-[55px] my-6 md:my-10 relative">
          {/* <div className="absolute hidden md:block  left-9 top-[50%] translate-y-[-50%] z-[100] rounded-full p-2 bg-gray-100 shadow-md shadow-gray-300">
            <MdArrowForwardIos
              size={18}
              className="rotate-180 cursor-pointer text-gray-600"
              onClick={prevHandler}
            />
          </div> */}
          {/* <div className="absolute -left-8 top-0 bottom-0 w-[8%] h-full bg-gray-200 z-50 blur-lg bg-opacity-80 "></div> */}
          <Swiper
            slidesPerView={1.2}
            spaceBetween={10}
            // onActiveIndexChange={(index)=> setSlidesPerView(index)}
            loop={true}
            autoplay={{ delay: 5000 }}
            modules={[Autoplay]}
            // onSwiper={(swiper) => setSwiperRef(swiper)}
            className="mySwiper w-full"
          >
            {contentMobile}
          </Swiper>
          <div className="absolute -right-0 sm:-right-8 top-0 bottom-0 w-[20%] h-full bg-gradient-to-r from-transparent via-[rgba(255,255,255,0.8)] to-white z-50"></div>
          {/* <div className="hidden md:block absolute right-9 top-[50%] translate-y-[-50%] z-[100] rounded-full p-2 bg-gray-100 shadow-md shadow-gray-300">
            <MdArrowForwardIos
              size={18}
              className="cursor-pointer text-gray-600"
              onClick={nextHandler}
            />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default AfterLoginSlider;
