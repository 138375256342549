import Cookies from "js-cookie";
import React, { useState } from "react";
import { GiReceiveMoney } from "react-icons/gi";
import { useParams } from "react-router-dom";
import { useGetProductsByCategoryQuery } from "../../features/product/productApiSlice";
import ProductCard from "../Card/ProductCard";
import CommonComponent from "../CommonComponent/CommonComponent";
import Error from "../Error/Error";
import NoDataFound from "../NodataFound/NoDataFound";
import ProductCardSkeleton from "../Skeleton/ProductCardSkeleton";

const CashbackProduct = ({ path }) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [cashback, setCashBack] = useState("");

  const { categoryname } = useParams();
  let arr = categoryname?.split("-");
  for (let i = 0; i < arr?.length; i++) {
    arr[i] = arr[i].slice(0, 1).toUpperCase() + arr[i].slice(1);
  }
  const title = arr?.join(" ");
  // const { limit, page, cashback } = useSelector((state) => state.product);
  const access_token = Cookies.get("ayykori");
  const { data, isLoading, isSuccess, isError } =
    useGetProductsByCategoryQuery(
      {
        access_token,
        categoryname,
        page,
        limit,
        cashback,
      },
      { refetchOnReconnect: true }
    ) || {};

  let content = null;
  // handle loading case
  if (isLoading && !isSuccess) {
    content = (
      <>
        <div
          className="  w-full px-3 md:px-[55px] grid grid-cols-1 sm:grid-cols-2  md:grid-cols-3 lg:grid-cols-5
      gap-y-4 gap-x-4 my-10"
        >
          {Array(12)
            .fill()
            ?.map((item, i) => (
              <ProductCardSkeleton key={i} />
            ))}
        </div>
      </>
    );
  }
  // handle success case
  if (!isLoading && isSuccess && !isError) {
    content = (
      <>
        <div className=" w-full px-3 md:px-[55px] grid grid-cols-2 sm:grid-cols-2  md:grid-cols-3 lg:grid-cols-5 gap-y-3 md:gap-y-6 gap-x-3 md:gap-x-8">
          {data?.resData &&
            data?.resData?.map((item, i) => (
              <ProductCard
                key={i}
                data={{
                  id: item?._id,
                  image: item?.productImg,
                  name: item?.productTitle,
                  price: item?.productPrice,
                  rating: item?.productRating,
                  category: item?.category,
                  subCategory: item?.subCategory,
                  productDetails: item?.productDetails,
                  // brandId: item?.brandDetails[0]?._id,
                  brandId: item?.brandUnid,
                  productLink: item?.productLink,
                  brandimg: item?.brandimg,
                  discount: item?.productProfitInPercent,
                  profit: item?.cashbackProductProfitAmount,
                  whichBasisFav: "cashback",
                  buttontitle: "Get Cashback",
                  Icon: GiReceiveMoney,
                }}
              />
            ))}
        </div>
      </>
    );
  }
  // handle error case
  if (!isLoading && isError) {
    content = (
      <>
        <Error />
      </>
    );
  }
  // handle empty case
  if (!isLoading && isSuccess && data?.resData?.length === 0) {
    content = (
      <>
        <NoDataFound />
      </>
    );
  }

  return (
    <>
      <CommonComponent
        data={{
          title: "Best Deals To Buy",
          link: path ? path : "/cashback-products/all",
          linktitle: "See All",
          show: true,
          flashSale: true,
        }}
      ></CommonComponent>
      <div className=" mt-4 mb-8">{content}</div>
    </>
  );
};

export default CashbackProduct;
