import React from "react";
import { Link } from "react-router-dom";
import { categorystaticData } from "../../Data/categoryStaticBannerData";

const CategoryStaticBanner = () => {
  return (
    <div className="w-full px-3 md:px-[55px] my-8">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 items-center gap-4">
        {categorystaticData &&
          categorystaticData?.map((item, i) => (
            <Link
              key={i}
              to={`/cashback-products/${item?.category
                ?.toLocaleLowerCase()
                .replace(/\s/g, "-")}`}
            >
              <div
                className="  shadow-md rounded-lg 
     flex items-center justify-center"
              >
                <div className="relative w-full   ">
                  <img
                    className="w-full h-full  rounded-lg "
                    src={item.img}
                    alt=""
                  />
                  {/* <div className="w-[50%] absolute  bottom-5 left-5 flex flex-col items-start justify-between"> */}
                  {/* <h1
                      className=" px-2 py-2  text-lg md:text-base 
                   lg:text-lg text-gray-800 font-semibold"
                    >
                      {item.title}
                    </h1> */}
                  {/* <button
                      className=" mt-3 flex items-center  gap-2 
                text-white bg-primaryColor py-2  px-4 rounded-lg text-base whitespace-pre"
                    >
                      Shop Now <BsArrowRight />{" "}
                    </button> */}
                  {/* </div> */}
                </div>
              </div>
            </Link>
          ))}
      </div>
    </div>
  );
};

export default CategoryStaticBanner;
